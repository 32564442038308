<template>
	<span class="relative z-10 inline-flex shadow-sm rounded-md">
		<button class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 focus:z-10 focus:outline-none" type="button">
			Resolve {{ route.params.activity_type.toString() }}
		</button>
		<Menu as="span" class="-ml-px relative block">
			<MenuButton class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-primary-600 hover:bg-gray-100 focus:z-10 focus:outline-none">
				<span class="sr-only">Open options</span>
				<FontAwesomeIcon :icon="faCaretDown" aria-hidden="true" class="h-5 w-5" />
			</MenuButton>
			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform-gpu opacity-0 scale-95"
				enter-to-class="transform-gpu opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform-gpu opacity-100 scale-100"
				leave-to-class="transform-gpu opacity-0 scale-95"
			>
				<MenuItems class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white focus:outline-none">
					<div class="py-1">
						<MenuItem v-for="item in items" :key="item.name" v-slot="{ active }">
							<div role="button" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'z-50 block px-4 py-2 text-sm']" @click="item.onClick">
								{{ item.name }}
							</div>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
	</span>
</template>

<script setup lang="ts">
	import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
	import { useRoute } from 'vue-router';
	import { useActivityStore } from '@modules/activities/store';

	const route = useRoute();
	const activityStore = useActivityStore();

	const items = [
		{
			name: 'Reassign ' + route.params.activity_type.toString(),
			onClick: () => {
				activityStore.deleteActivity();
			},
		},
		{
			name: 'Clone ' + route.params.activity_type.toString(),
			onClick: () => {
				activityStore.cloneActivity();
			},
		},
		{
			name: 'Archive ' + route.params.activity_type.toString(),
			onClick: () => {
				activityStore.archiveActivity();
			},
		},
		{
			name: 'Close ' + route.params.activity_type.toString(),
			onClick: () => {
				activityStore.deleteActivity();
			},
		},
	];
</script>
